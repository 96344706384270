export const CoachData = [
  {
    id: 1,
    CoachDetail: {
      name: "Help with Interviews",
      span: "Interview coming up? Let's prepare to nail it!",
      prompt: "How can I prepare for a job interview in an unfamiliar industry?"
    }
  },
  {
    id: 2,
    CoachDetail: {
      name: "Recruiting Advice",
      span: "Got someone to hire? Let's find the best candidate",
      prompt:
        "I am an employer, recommend a strategy to hire workers in Scotland"
    }
  },
  {
    id: 3,
    CoachDetail: {
      name: "Career questions",
      span: "Advise me a career option!",
      prompt: "Is 50 too old to change careers?"
    }
  },
  {
    id: 4,
    CoachDetail: {
      name: "Startup investment",
      span: "Ask me a startup question!",
      prompt: "Ask me difficult questions and rate my startup for investment"
    }
  }
];

export const JobData = [
  {
    id: 1,
    JobDetail: {
      name: "Career Growth Opportunities",
      span: "Recommend AI developers with Python and React for remote jobs",
      prompt:
        "What are some remote job opportunities available for AI developers proficient in Python and React, and how can I enhance my career growth in these areas?"
    }
  },
  {
    id: 2,
    JobDetail: {
      name: "Cross-Industry Equivalents",
      span: "Can you suggest data scientist jobs in the UK for recent graduates?",
      prompt:
        "Can you recommend entry-level data scientist positions in the United Kingdom suitable for recent graduates, along with tips on how to stand out in the application process?"
    }
  },
  {
    id: 3,

    JobDetail: {
      name: "Balanced Careers",
      span: "I want to explore job opportunities in green energy",
      prompt:
        "What are some job opportunities in the green energy sector, and how can I balance my career goals with contributing to sustainable initiatives?"
    }
  },
  {
    id: 4,

    JobDetail: {
      name: "Remote jobs",
      span: "Show me remote positions in cloud architecture",
      prompt:
        "Can you display remote job openings in the field of cloud architecture, including requirements and companies offering these positions?"
    }
  },
  {
    id: 5,

    JobDetail: {
      name: "Personalized Career",
      span: "Find me the latest mobile development roles in the UK",
      prompt:
        "Could you please provide me with the latest job listings for mobile development roles in the United Kingdom that match my skills and experience?"
    }
  }
];
