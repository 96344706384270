import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaUser, FaBars, FaTimes } from "react-icons/fa";

function Navbar() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <>
      <nav className="flex items-center justify-between w-full h-[60px] pl-4 sticky top-0 z-50 bg-white">
        <div className="flex items-center justify-start w-1/4">
          <button
            className="sm:hidden text-primary hover:text-white w-auto h-[60px] px-4 flex items-center gap-3"
            onClick={toggleSidebar}
          >
            <FaBars />
          </button>
          {/* Optional: Add a logo or branding here */}
        </div>
        <div className="flex items-center justify-end w-3/4">
          <div className="hidden sm:flex gap-10 items-center justify-center pe-4">
            <Link to="#" className="hover:text-primary">
              Search Jobs
            </Link>
            <Link to="#" className="hover:text-primary">
              Search Organizations
            </Link>
            <Link to="#" className="hover:text-primary">
              Career Hub
            </Link>
            <Link to="#" className="hover:text-primary">
              Advertise with us
            </Link>
          </div>
          <button className="bg-[#f6f6f6] text-primary hover:bg-primary hover:text-white w-auto h-[60px] px-4 flex items-center gap-3">
            <FaUser />
            Log in / Register
          </button>
        </div>
      </nav>

      <div
        className={`fixed top-0 left-0 w-[250px] h-full bg-white shadow-lg z-50 transform ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        } transition-transform duration-300 ease-in-out`}
      >
        <button
          className="text-primary hover:text-white w-auto h-[60px] px-4 flex items-center gap-3"
          onClick={toggleSidebar}
        >
          <FaTimes />
        </button>
        <div className="flex flex-col gap-4 items-start pl-4 pt-4">
          <Link to="#" className="hover:text-primary" onClick={toggleSidebar}>
            Search Jobs
          </Link>
          <Link to="#" className="hover:text-primary" onClick={toggleSidebar}>
            Search Organizations
          </Link>
          <Link to="#" className="hover:text-primary" onClick={toggleSidebar}>
            Career Hub
          </Link>
          <Link to="#" className="hover:text-primary" onClick={toggleSidebar}>
            Advertise with us
          </Link>
        </div>
      </div>
    </>
  );
}

export default Navbar;
