import React, { useContext, useState } from "react";
import ChatModal from "./modals/ChatModal";
import dog from "../assets/dog.gif";
import GlobalContext from "../context/GlobalContext";
function ChatBot({
  jobDebugMessage,
  setJobDebugMessage,
  careerDebugMessage,
  setCareerDebugMessage
}) {
  const [isOpen, setIsOpen] = useState(false);
  const { setActiveTab } = useContext(GlobalContext);

  return (
    <>
      <div
        onClick={() => {
          setIsOpen(true);
          setActiveTab("1");
        }}
        className="relative xs:w-[70px] lg:w-[130px] lg:h-[50px] flex items-center justify-center cursor-pointer"
      >
        <img src={dog} alt="" />
      </div>
      <ChatModal
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        jobDebugMessage={jobDebugMessage}
        setJobDebugMessage={setJobDebugMessage}
        careerDebugMessage={careerDebugMessage}
        setCareerDebugMessage={setCareerDebugMessage}
      />
    </>
  );
}

export default ChatBot;
