import React from "react";
import Footer from "../components/shared/Footer";
import Navbar from "../components/shared/Navbar";
import { Link } from "react-router-dom";
import { RiNpmjsFill } from "react-icons/ri";
import { FaUser } from "react-icons/fa";
import { FaQuestion } from "react-icons/fa";

function HomePage() {
  return (
    <>
      <Navbar />
      <div className="w-full h-auto sm:h-auto lg:h-auto 2xl:h-auto">
        <div
          style={{
            backgroundImage: `url(/images/Tobermory+Nighttime.jpeg)`,
            backgroundSize: "cover",
            objectPosition: "center center",
            backgroundRepeat: "no-repeat",
            width: "100%",
            height: "70vh"
          }}
          className="flex flex-col justify-center items-center relative lg:hidden"
        >
          <h1
            className="text-white text-center font-semibold sm:text-2xl lg:text-4xl 2xl:text-5xl"
            style={{
              textShadow: "0 1px 1px rgba(27, 27, 27, 0.7)",
              lineHeight: "1.2"
            }}
          >
            Scotland's job website for the
            <br /> public and third sector
          </h1>
          <button
            style={{ outline: "3px solid white" }}
            className="bg-white text-primary px-5 py-2 rounded-md font-semibold border-3 border-white hover:border-primary mt-6"
          >
            Search all jobs
          </button>
          <div
            className="flex items-center justify-center gap-1 absolute bottom-0 py-6 w-full flex-wrap"
            style={{ background: "rgba(27, 27, 27, 0.35)" }}
          >
            <input
              type="text"
              placeholder="Keywords e.g teacher"
              className="h-12 px-8 xs:mx-5 md:mx-0 py-2 bg-white border-2 border-white rounded-md font-semibold text-gray-800 w-full sm:w-auto"
            />
            <input
              type="text"
              placeholder="Postcode or town"
              className="h-12 px-8 xs:mx-5 md:mx-0 py-2 bg-white border-2 border-white rounded-md font-semibold text-gray-800 w-full sm:w-auto"
            />
            <button className="bg-primary text-white xs:w-full md:w-auto xs:mx-5 md:mx-0 px-5 h-12 rounded-md hover:bg-blue-700">
              Search
            </button>
          </div>
        </div>

        <div
          style={{
            backgroundImage: `url(/images/Tobermory+Nighttime.jpeg)`,
            backgroundSize: "cover",
            objectPosition: "center center",
            backgroundRepeat: "no-repeat",
            width: "100%",
            height: "80vh"
          }}
          className="flex flex-col justify-center items-center relative hidden lg:flex"
        >
          <h1
            className="text-white text-center font-semibold sm:text-2xl lg:text-4xl 2xl:text-5xl"
            style={{
              textShadow: "0 1px 1px rgba(27, 27, 27, 0.7)",
              lineHeight: "1.2"
            }}
          >
            Scotland's job website for the
            <br /> public and third sector
          </h1>
          <button
            style={{ outline: "3px solid white" }}
            className="bg-white text-primary px-5 py-2 rounded-md font-semibold border-3 border-white hover:border-primary mt-6"
          >
            Search all jobs
          </button>
          <div
            className="flex items-center justify-center gap-1 absolute bottom-0 py-6 w-full flex-wrap"
            style={{ background: "rgba(27, 27, 27, 0.35)" }}
          >
            <input
              type="text"
              placeholder="Keywords e.g teacher"
              className="h-12 px-8 xs:mx-5 md:mx-0 py-2 bg-white border-2 border-white rounded-md font-semibold text-gray-800 w-full sm:w-auto"
            />
            <input
              type="text"
              placeholder="Postcode or town"
              className="h-12 px-8 xs:mx-5 md:mx-0 py-2 bg-white border-2 border-white rounded-md font-semibold text-gray-800 w-full sm:w-auto"
            />
            <button className="bg-primary text-white xs:w-full md:w-auto xs:mx-5 md:mx-0 px-5 h-12 rounded-md hover:bg-blue-700">
              Search
            </button>
          </div>
        </div>
      </div>

      <section className="flex justify-between xs:flex-wrap md:no-wrap gap-2 bg-gray-200 p-2 text-white w-full ">
        <Link
          to="#"
          className="xs:w-full md:w-[49%] lg:h-80 xs:h-[250px] md:h-[250px] xl:h-90 2xl:h-[450px] relative rounded-md"
        >
          <div
            className="rounded-md w-full h-full bg-cover bg-center"
            style={{
              backgroundImage: `url(/images/copy-Michael_and_Cameron_1.jpg)`
            }}
          >
            <div
              className="overlay w-full h-full flex flex-col justify-end p-4"
              style={{
                background: "rgba(255, 255, 255, 0.30)"
              }}
            >
              <h1 className="text-xl lg:text-2xl 2xl:text-3xl">
                Personal Assistants vacancies
              </h1>
              <p className="text-lg lg:text-xl 2xl:text-2xl">
                A role like no other
              </p>
            </div>
          </div>
          <div
            className="hidden md:block absolute inset-0 bg-cover bg-center rounded-md"
            style={{
              backgroundImage: `url(/images/Michael_and_Cameron_1.jpg)`
            }}
          >
            <div
              className="overlay w-full h-full flex flex-col justify-end p-4"
              style={{
                background: "rgba(255, 255, 255, 0.30)"
              }}
            >
              <h1 className="text-xl lg:text-2xl 2xl:text-3xl">
                Personal Assistants vacancies
              </h1>
              <p className="text-lg lg:text-xl 2xl:text-2xl">
                A role like no other
              </p>
            </div>
          </div>
        </Link>

        <Link
          to="#"
          className="xs:w-full md:w-[49%] lg:h-80 xs:h-[250px] md:h-[250px] xl:h-90 2xl:h-[450px] relative rounded-md"
        >
          <div
            className="rounded-md w-full h-full bg-cover bg-center"
            style={{
              backgroundImage: `url(/images/copy-ASC_Union_010b.jpg)`
            }}
          >
            <div
              className="overlay w-full h-full flex flex-col justify-end p-4"
              style={{
                background: "rgba(255, 255, 255, 0.30)"
              }}
            >
              <h1 className="text-xl lg:text-2xl 2xl:text-3xl">
                Adult Social Care
              </h1>
              <p className="text-lg lg:text-xl 2xl:text-2xl">
                Make a difference to people's lives
              </p>
            </div>
          </div>
          <div
            className="hidden md:block absolute inset-0 bg-cover bg-center rounded-md"
            style={{
              backgroundImage: `url(/images/ASC_Union_010b.jpg)`
            }}
          >
            <div
              className="overlay w-full h-full flex flex-col justify-end p-4"
              style={{
                background: "rgba(255, 255, 255, 0.30)"
              }}
            >
              <h1 className="text-xl lg:text-2xl 2xl:text-3xl">
                Adult Social Care
              </h1>
              <p className="text-lg lg:text-xl 2xl:text-2xl">
                Make a difference to people's lives
              </p>
            </div>
          </div>
        </Link>
      </section>
      <section
        className="flex justify-center items-center xs:flex-wrap md:flex-nowrap  gap-4 bg-gray-100 p-4 xs:md-8 md:py-8 lg:py-16 xs:px-4 md:px-8"
        style={{
          boxShadow:
            "inset 0 8px 8px -8px rgba(27, 27, 27, 0.08), inset 0 -8px 8px -8px rgba(27, 27, 27, 0.08)"
        }}
      >
        <a
          href="#"
          className="bg-white xs:w-[100%] xs:h-20  lg:h-28 rounded-md flex items-center shadow-md"
        >
          <span className="w-28 xs:h-20 lg:h-28 flex justify-center items-center border-r-2 border-gray-100">
            <div className="bg-primary text-white w-9 h-9 flex justify-center items-center rounded-full">
              <RiNpmjsFill />
            </div>
          </span>
          <span className="lg:text-lg font-semibold text-gray-600 flex items-center xs:pl-6 lg:pl-4 2xl:pl-6">
            About myjobscotland
          </span>
        </a>
        <a
          href="#"
          className="bg-white xs:w-[100%] xs:h-20  lg:h-28 rounded-md flex items-center shadow-md"
        >
          <span className="w-28 xs:h-20  lg:h-28 flex justify-center items-center border-r-2 border-gray-100">
            <div className="bg-primary text-white w-9 h-9 flex justify-center items-center rounded-full">
              <FaUser />
            </div>
          </span>
          <span className="lg:text-lg font-semibold text-gray-600 flex items-center pl-6">
            Your Career
          </span>
        </a>
        <a
          href="#"
          className="bg-white xs:w-[100%] xs:h-20  lg:h-28 rounded-md flex items-center shadow-md"
        >
          <span className="w-28 xs:h-20  lg:h-28 flex justify-center items-center border-r-2 border-gray-100">
            <div className="bg-primary text-white w-9 h-9 flex justify-center items-center rounded-full">
              <FaQuestion />
            </div>
          </span>
          <span className="lg:text-lg font-semibold text-gray-600 flex items-center pl-6">
            Help
          </span>
        </a>
      </section>
      <Footer />
    </>
  );
}

export default HomePage;
