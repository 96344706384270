/* eslint-disable react/jsx-no-target-blank */
import React, { useRef, useState, Fragment, useContext } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { AiOutlineClose } from "react-icons/ai";
import MainComponents from "../shared/MainComponents";
import GlobalContext from "../../context/GlobalContext";

function ChatModal(props) {
  const cancelButtonRef = useRef(null);
  const BASE_URL = process.env.REACT_APP_BASE_URL_SOCKET;
  const jobSocketUrl = `wss://${BASE_URL}/ws/demo/job_search/`;
  const careerSocketUrl = `wss://${BASE_URL}/ws/career_coach/`;

  const {
    // jobs
    jobInputQuery,
    setJobInputQuery,
    setJobChatData,
    setJobChatLoading,
    setJobErrorMessage,
    setJobSendBtnLoading,
    jobSendBtnLoading,
    clearChatData,
    jobStopDebug,
    setJobStopDebug,
    setJobStoredQuery,
    setJobGenerating,
    jobGenerating,
    jobChatData,
    jobChatLoading,
    jobStoredQuery,

    // career
    careerInputQuery,
    setCareerInputQuery,
    setCareerChatData,
    careerSendBtnLoading,
    setCareerStoredQuery,
    clearCareerChatData,
    careerChatData,
    careerChatLoading,
    setCareerSendBtnLoading,
    setCareerStopDebug,
    setCareerErrorMessage,
    setCareerChatLoading,
    careerStoredQuery,
    careerStopDebug,
    setCareerGenerating,
    careerGenerating
  } = useContext(GlobalContext);
  const { activeTab, setActiveTab } = useContext(GlobalContext);

  return (
    <>
      <Transition.Root show={props.isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-[9999999999]"
          initialFocus={cancelButtonRef}
          onClose={() => props.setIsOpen(false)}
        >
          <div className="fixed inset-0 z-10 overflow-y-auto bg-black bg-opacity-75 ">
            <div className="flex min-h-full justify-end p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="modalpanel absolute top-0 xs:left-0 md:left-[10%] mt-12 mr-4 transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all 2xl:w-[600px] lg:w-[500px] xs:w-[407px] h-auto xs:mx-2 pt-4">
                  <div className="cursor-pointer absolute right-2 top-2 bg-[#F4F4F4] rounded-[50%] p-1 text-[#A0A0A0]">
                    <AiOutlineClose onClick={() => props.setIsOpen(false)} />
                  </div>
                  <div className="flex justify-around">
                    <div
                      className={`cursor-pointer px-4 py-2 flex items-center justify-center w-[50%] border-b-2 gray  ${
                        activeTab === "1"
                          ? "border-b-2 border-[#FF5E59] text-[#1E2444] font-[600] text-[22px]"
                          : "text-[#718096] font-[500] text-[22px]"
                      }`}
                      onClick={() => setActiveTab("1")}
                    >
                      Jobs
                    </div>
                    <div
                      className={`cursor-pointer px-4 py-2 flex items-center justify-center w-[50%] border-b-2 gray ${
                        activeTab === "0"
                          ? "border-b-2 border-[#FF5E59] text-[#1E2444] font-[600] text-[22px]"
                          : "text-[#718096] font-[500] text-[22px]"
                      }`}
                      onClick={() => setActiveTab("0")}
                    >
                      Coach
                    </div>
                  </div>
                  <div>
                    {activeTab === "0" && (
                      <div>
                        <MainComponents
                          debugMessage={props.careerDebugMessage}
                          setDebugMessage={props.setCareerDebugMessage}
                          inputQuery={careerInputQuery}
                          setInputQuery={setCareerInputQuery}
                          setChatData={setCareerChatData}
                          setChatLoading={setCareerChatLoading}
                          setErrorMessage={setCareerErrorMessage}
                          setBtnLoading={setCareerSendBtnLoading}
                          btnLoading={careerSendBtnLoading}
                          clearChatData={clearCareerChatData}
                          stopDebug={careerStopDebug}
                          setStopDebug={setCareerStopDebug}
                          setStoredQuery={setCareerStoredQuery}
                          setGenerating={setCareerGenerating}
                          generating={careerGenerating}
                          webSocketUrl={careerSocketUrl}
                          chatData={careerChatData}
                          chatLoading={careerChatLoading}
                        />
                      </div>
                    )}
                    {activeTab === "1" && (
                      <div>
                        <MainComponents
                          debugMessage={props.jobDebugMessage}
                          setDebugMessage={props.setJobDebugMessage}
                          inputQuery={jobInputQuery}
                          setInputQuery={setJobInputQuery}
                          setChatData={setJobChatData}
                          setChatLoading={setJobChatLoading}
                          setErrorMessage={setJobErrorMessage}
                          setBtnLoading={setJobSendBtnLoading}
                          btnLoading={jobSendBtnLoading}
                          clearChatData={clearChatData}
                          stopDebug={jobStopDebug}
                          setStopDebug={setJobStopDebug}
                          setStoredQuery={setJobStoredQuery}
                          setGenerating={setJobGenerating}
                          generating={jobGenerating}
                          webSocketUrl={jobSocketUrl}
                          chatData={jobChatData}
                          chatLoading={jobChatLoading}
                          storedQuery={jobStoredQuery}
                        />
                      </div>
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

export default ChatModal;
