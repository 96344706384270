import Input from "./Input";
import ChatView from "./ChatView";
import { useRef } from "react";

function MainComponents({
  inputQuery,
  setInputQuery,
  setChatData,
  setChatLoading,
  setErrorMessage,
  setBtnLoading,
  btnLoading,
  clearChatData,
  stopDebug,
  setStopDebug,
  setStoredQuery,
  setGenerating,
  generating,
  setDebugMessage,
  webSocketUrl,
  debugMessage,
  chatData,
  chatLoading,
  storedQuery,
  sidebarVisible
}) {
  const webSocket = useRef(null);
  return (
    <div className="flex flex-col h-full w-full ">
      <div className="flex flex-col items-center 2xl:mt-0 lg:mt-1   h-full 2xl:pt-0 lg:pt-2 ">
        <div className="  w-[100%]">
          <ChatView
            debugMessage={debugMessage}
            setDebugMessage={setDebugMessage}
            chatData={chatData}
            chatLoading={chatLoading}
            setBtnLoading={setBtnLoading}
            setStopDebug={setStopDebug}
            setErrorMessage={setErrorMessage}
            setChatLoading={setChatLoading}
            storedQuery={storedQuery}
            stopDebug={stopDebug}
            generating={generating}
            webSocket={webSocket}
            sidebarVisible={sidebarVisible}
          />
        </div>
        <div className="w-full ">
          <Input
            debugMessage={debugMessage}
            setDebugMessage={setDebugMessage}
            inputQuery={inputQuery}
            setInputQuery={setInputQuery}
            setChatData={setChatData}
            setChatLoading={setChatLoading}
            setErrorMessage={setErrorMessage}
            setBtnLoading={setBtnLoading}
            btnLoading={btnLoading}
            clearChatData={clearChatData}
            stopDebug={stopDebug}
            setStopDebug={setStopDebug}
            setStoredQuery={setStoredQuery}
            setGenerating={setGenerating}
            generating={generating}
            webSocketUrl={webSocketUrl}
            webSocket={webSocket}
          />
        </div>
      </div>
    </div>
  );
}

export default MainComponents;
