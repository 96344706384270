import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import HomePage from "../pages/HomePage";
import ChatBot from "../components/ChatBot";

const Routing = () => {
  const [careerDebugMessage, setCareerDebugMessage] = useState("");
  const [jobDebugMessage, setJobDebugMessage] = useState("");
  return (
    <>
      <Routes>
        <Route path="/" element={<HomePage />} />
      </Routes>
      <div className="xs:left-0 lg:left-8 xs:bottom-2 lg:bottom-20 fixed z-20">
        <ChatBot
          jobDebugMessage={jobDebugMessage}
          setJobDebugMessage={setJobDebugMessage}
          careerDebugMessage={careerDebugMessage}
          setCareerDebugMessage={setCareerDebugMessage}
        />
      </div>
    </>
  );
};

export default Routing;
