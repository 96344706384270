import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { GoogleOAuthProvider } from "@react-oauth/google";
import "@fontsource/roboto";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <GoogleOAuthProvider clientId="686937995225-omi3go61gruk8ieeas1cu7v7pfve4591.apps.googleusercontent.com">
    {/* <React.StrictMode> */}
    <App />
    {/* </React.StrictMode> */}
  </GoogleOAuthProvider>
);

reportWebVitals();
