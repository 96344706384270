import React from "react";
import Routing from "./routing/Routing";
import "./App.css";
import { GlobalProvider } from "./context/GlobalContext";
import { BrowserRouter } from "react-router-dom";

function App() {
  return (
    <>
      <GlobalProvider>
        <BrowserRouter>
          <Routing />
        </BrowserRouter>
      </GlobalProvider>
    </>
  );
}

export default App;
