import React, { createContext, useReducer } from "react";
import AppReducer from "./AppReducer";

const initialState = {
  userInfo: null,
  suggestionQuery: "",
  jobChatData: [],
  jobChatLoading: false,
  jobErrorMessage: null,
  jobInputQuery: null,
  jobSendBtnLoading: false,
  jobStopDebug: true,
  jobStoredQuery: null,
  jobGenerating: false,

  profileChatData: [],
  profileChatLoading: false,
  profileErrorMessage: null,
  profileInputQuery: null,
  profileSendBtnLoading: false,
  profileStopDebug: true,
  profileStoredQuery: null,
  profileGenerating: false,

  newProfileChatData: [],
  newProfileChatLoading: false,
  newProfileErrorMessage: null,
  newProfileInputQuery: null,
  newProfileSendBtnLoading: false,
  newProfileStopDebug: true,
  newProfileStoredQuery: null,
  newProfileGenerating: false,

  docChatData: [],
  docChatLoading: false,
  docErrorMessage: null,
  docInputQuery: null,
  docSendBtnLoading: false,
  docStopDebug: true,
  docStoredQuery: null,
  docGenerating: false,

  careerChatData: [],
  careerChatLoading: false,
  careerErrorMessage: null,
  careerInputQuery: null,
  careerSendBtnLoading: false,
  careerStopDebug: true,
  careerStoredQuery: null,
  careerGenerating: false,

  activeTab: 1,
  cvDetail: null
};

const GlobalContext = createContext(initialState);
export default GlobalContext;

export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);
  const setUserInfo = (userInfo) => {
    dispatch({
      type: "SET_USER_INFO",
      payload: userInfo
    });
  };
  const setSuggestionQuery = (suggestionQuery) => {
    dispatch({
      type: "SET_SUGGESTION_QUERY",
      payload: suggestionQuery
    });
  };

  // jobs function
  const setJobChatData = (jobChatData) => {
    dispatch({
      type: "SET_JOB_CHAT_DATA",
      payload: jobChatData
    });
  };
  const setJobChatLoading = (jobChatLoading) => {
    dispatch({
      type: "SET_JOB_CHAT_LOADING",
      payload: jobChatLoading
    });
  };
  const setJobErrorMessage = (jobErrorMessage) => {
    dispatch({
      type: "SET_JOB_ERROR_MESSAGE",
      payload: jobErrorMessage
    });
  };
  const setJobInputQuery = (jobInputQuery) => {
    dispatch({
      type: "SET_JOB_INPUT_QUERY",
      payload: jobInputQuery
    });
  };
  const setJobSendBtnLoading = (jobSendBtnLoading) => {
    dispatch({
      type: "SET_JOB_SEND_BTN_LOADING",
      payload: jobSendBtnLoading
    });
  };
  const setJobStopDebug = (jobStopDebug) => {
    dispatch({
      type: "SET_JOB_STOPDEBUG",
      payload: jobStopDebug
    });
  };
  const setJobStoredQuery = (jobStoredQuery) => {
    dispatch({
      type: "SET_JOB_STOREDQUERY",
      payload: jobStoredQuery
    });
  };
  const setJobGenerating = (jobGenerating) => {
    dispatch({
      type: "SET_JOB_GENERATING",
      payload: jobGenerating
    });
  };
  const clearChatData = () => {
    dispatch({
      type: "CLEAR_CHAT_DATA"
    });
  };
  // profile function
  const setProfileChatData = (profileChatData) => {
    dispatch({
      type: "SET_PROFILE_CHAT_DATA",
      payload: profileChatData
    });
  };
  const setProfileChatLoading = (profileChatLoading) => {
    dispatch({
      type: "SET_PROFILE_CHAT_LOADING",
      payload: profileChatLoading
    });
  };
  const setProfileErrorMessage = (profileErrorMessage) => {
    dispatch({
      type: "SET_PROFILE_ERROR_MESSAGE",
      payload: profileErrorMessage
    });
  };
  const setProfileInputQuery = (profileInputQuery) => {
    dispatch({
      type: "SET_PROFILE_INPUT_QUERY",
      payload: profileInputQuery
    });
  };
  const setProfileSendBtnLoading = (profileSendBtnLoading) => {
    dispatch({
      type: "SET_PROFILE_SEND_BTN_LOADING",
      payload: profileSendBtnLoading
    });
  };
  const setProfileStopDebug = (profileStopDebug) => {
    dispatch({
      type: "SET_PROFILE_STOPDEBUG",
      payload: profileStopDebug
    });
  };
  const setProfileStoredQuery = (profileStoredQuery) => {
    dispatch({
      type: "SET_PROFILE_STOREDQUERY",
      payload: profileStoredQuery
    });
  };
  const setProfileGenerating = (profileGenerating) => {
    dispatch({
      type: "SET_PROFILE_GENERATING",
      payload: profileGenerating
    });
  };
  const clearProfileChatData = () => {
    dispatch({
      type: "CLEAR_PROFILE_CHAT_DATA"
    });
  };
  // new  profile function
  const setNewProfileChatData = (newProfileChatData) => {
    dispatch({
      type: "SET_NEW_PROFILE_CHAT_DATA",
      payload: newProfileChatData
    });
  };
  const setNewProfileChatLoading = (newProfileChatLoading) => {
    dispatch({
      type: "SET_NEW_PROFILE_CHAT_LOADING",
      payload: newProfileChatLoading
    });
  };
  const setNewProfileErrorMessage = (newProfileErrorMessage) => {
    dispatch({
      type: "SET_NEW_PROFILE_ERROR_MESSAGE",
      payload: newProfileErrorMessage
    });
  };
  const setNewProfileInputQuery = (newProfileInputQuery) => {
    dispatch({
      type: "SET_NEW_PROFILE_INPUT_QUERY",
      payload: newProfileInputQuery
    });
  };
  const setNewProfileSendBtnLoading = (newProfileSendBtnLoading) => {
    dispatch({
      type: "SET_NEW_PROFILE_SEND_BTN_LOADING",
      payload: newProfileSendBtnLoading
    });
  };
  const setNewProfileStopDebug = (newProfileStopDebug) => {
    dispatch({
      type: "SET_NEW_PROFILE_STOPDEBUG",
      payload: newProfileStopDebug
    });
  };
  const setNewProfileStoredQuery = (newProfileStoredQuery) => {
    dispatch({
      type: "SET_NEW_PROFILE_STOREDQUERY",
      payload: newProfileStoredQuery
    });
  };
  const setNewProfileGenerating = (newProfileGenerating) => {
    dispatch({
      type: "SET_NEW_PROFILE_GENERATING",
      payload: newProfileGenerating
    });
  };
  const clearNewProfileChatData = () => {
    dispatch({
      type: "CLEAR_NEW_PROFILE_CHAT_DATA"
    });
  };
  // doc function
  const setDocChatData = (docChatData) => {
    dispatch({
      type: "SET_DOC_CHAT_DATA",
      payload: docChatData
    });
  };
  const setDocChatLoading = (docChatLoading) => {
    dispatch({
      type: "SET_DOC_CHAT_LOADING",
      payload: docChatLoading
    });
  };
  const setDocErrorMessage = (docErrorMessage) => {
    dispatch({
      type: "SET_DOC_ERROR_MESSAGE",
      payload: docErrorMessage
    });
  };
  const setDocInputQuery = (docInputQuery) => {
    dispatch({
      type: "SET_DOC_INPUT_QUERY",
      payload: docInputQuery
    });
  };
  const setDocSendBtnLoading = (docSendBtnLoading) => {
    dispatch({
      type: "SET_DOC_SEND_BTN_LOADING",
      payload: docSendBtnLoading
    });
  };

  const setDocStopDebug = (docStopDebug) => {
    dispatch({
      type: "SET_DOC_STOPDEBUG",
      payload: docStopDebug
    });
  };
  const setDocStoredQuery = (docStoredQuery) => {
    dispatch({
      type: "SET_DOC_STOREDQUERY",
      payload: docStoredQuery
    });
  };
  const setDocGenerating = (docGenerating) => {
    dispatch({
      type: "SET_DOC_GENERATING",
      payload: docGenerating
    });
  };
  const clearDocChatData = () => {
    dispatch({
      type: "CLEAR_DOC_CHAT_DATA"
    });
  };
  // career function
  const setCareerChatData = (careerChatData) => {
    dispatch({
      type: "SET_CAREER_CHAT_DATA",
      payload: careerChatData
    });
  };
  const setCareerChatLoading = (careerChatLoading) => {
    dispatch({
      type: "SET_CAREER_CHAT_LOADING",
      payload: careerChatLoading
    });
  };
  const setCareerErrorMessage = (careerErrorMessage) => {
    dispatch({
      type: "SET_CAREER_ERROR_MESSAGE",
      payload: careerErrorMessage
    });
  };
  const setCareerInputQuery = (careerInputQuery) => {
    dispatch({
      type: "SET_CAREER_INPUT_QUERY",
      payload: careerInputQuery
    });
  };
  const setCareerSendBtnLoading = (careerSendBtnLoading) => {
    dispatch({
      type: "SET_CAREER_SEND_BTN_LOADING",
      payload: careerSendBtnLoading
    });
  };

  const setCareerStopDebug = (careerStopDebug) => {
    dispatch({
      type: "SET_CAREER_STOPDEBUG",
      payload: careerStopDebug
    });
  };
  const setCareerStoredQuery = (careerStoredQuery) => {
    dispatch({
      type: "SET_CAREER_STOREDQUERY",
      payload: careerStoredQuery
    });
  };
  const setCareerGenerating = (careerGenerating) => {
    dispatch({
      type: "SET_CAREER_GENERATING",
      payload: careerGenerating
    });
  };
  const clearCareerChatData = () => {
    dispatch({
      type: "CLEAR_CAREER_CHAT_DATA"
    });
  };

  // active tab
  const setActiveTab = (activeTab) => {
    dispatch({
      type: "SET_ACTIVE_TABS",
      payload: activeTab
    });
  };

  // upload cv
  const setCVDetail = (cvDetail) => {
    dispatch({
      type: "SET_UPLOAD_CV_DATA",
      payload: cvDetail
    });
  };
  return (
    <GlobalContext.Provider
      value={{
        userInfo: state.userInfo,
        setUserInfo,
        suggestionQuery: state.suggestionQuery,
        setSuggestionQuery,

        // job
        jobChatData: state.jobChatData,
        setJobChatData,
        jobChatLoading: state.jobChatLoading,
        setJobChatLoading,
        jobErrorMessage: state.jobErrorMessage,
        setJobErrorMessage,
        jobInputQuery: state.jobInputQuery,
        setJobInputQuery,
        jobSendBtnLoading: state.jobSendBtnLoading,
        setJobSendBtnLoading,
        jobStopDebug: state.jobStopDebug,
        setJobStopDebug,
        jobStoredQuery: state.jobStoredQuery,
        setJobStoredQuery,
        jobGenerating: state.jobGenerating,
        setJobGenerating,
        clearChatData,
        // profile
        profileChatData: state.profileChatData,
        setProfileChatData,
        profileChatLoading: state.profileChatLoading,
        setProfileChatLoading,
        profileErrorMessage: state.profileErrorMessage,
        setProfileErrorMessage,
        profileInputQuery: state.profileInputQuery,
        setProfileInputQuery,
        profileSendBtnLoading: state.profileSendBtnLoading,
        setProfileSendBtnLoading,
        profileStopDebug: state.profileStopDebug,
        setProfileStopDebug,
        profileStoredQuery: state.profileStoredQuery,
        setProfileStoredQuery,
        profileGenerating: state.profileGenerating,
        setProfileGenerating,
        clearProfileChatData,
        // new profile
        newProfileChatData: state.newProfileChatData,
        setNewProfileChatData,
        newProfileChatLoading: state.newProfileChatLoading,
        setNewProfileChatLoading,
        newProfileErrorMessage: state.newProfileErrorMessage,
        setNewProfileErrorMessage,
        newProfileInputQuery: state.newProfileInputQuery,
        setNewProfileInputQuery,
        newProfileSendBtnLoading: state.newProfileSendBtnLoading,
        setNewProfileSendBtnLoading,
        newProfileStopDebug: state.newProfileStopDebug,
        setNewProfileStopDebug,
        newProfileStoredQuery: state.newProfileStoredQuery,
        setNewProfileStoredQuery,
        newProfileGenerating: state.newProfileGenerating,
        setNewProfileGenerating,
        clearNewProfileChatData,
        // doc
        docChatData: state.docChatData,
        setDocChatData,
        docChatLoading: state.docChatLoading,
        setDocChatLoading,
        docErrorMessage: state.docErrorMessage,
        setDocErrorMessage,
        docInputQuery: state.docInputQuery,
        setDocInputQuery,
        docSendBtnLoading: state.docSendBtnLoading,
        setDocSendBtnLoading,
        docStopDebug: state.docStopDebug,
        setDocStopDebug,
        docStoredQuery: state.docStoredQuery,
        setDocStoredQuery,
        docGenerating: state.docGenerating,
        setDocGenerating,
        clearDocChatData,
        //career
        careerChatData: state.careerChatData,
        setCareerChatData,
        careerChatLoading: state.careerChatLoading,
        setCareerChatLoading,
        careerErrorMessage: state.careerErrorMessage,
        setCareerErrorMessage,
        careerInputQuery: state.careerInputQuery,
        setCareerInputQuery,
        careerSendBtnLoading: state.careerSendBtnLoading,
        setCareerSendBtnLoading,
        careerStopDebug: state.careerStopDebug,
        setCareerStopDebug,
        careerStoredQuery: state.careerStoredQuery,
        setCareerStoredQuery,
        careerGenerating: state.careerGenerating,
        setCareerGenerating,
        clearCareerChatData,

        // active tab
        activeTab: state.activeTab,
        setActiveTab,
        // upload cv
        cvDetail: state.cvDetail,
        setCVDetail
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
