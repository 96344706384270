import React, { useContext, useEffect, useRef, useState } from "react";
import "../../styles/ConversationView.css";
import ReactMarkdown from "react-markdown";
import GlobalContext from "../../context/GlobalContext";
// import { GrRotateRight } from "react-icons/gr";
// import dog2 from "../../assets/Dog-4.png-final 4.png";
import { JobData, CoachData } from "../../../src/static-data/Coach";

function ChatView({
  debugMessage,
  setDebugMessage,
  chatData,
  chatLoading,
  setBtnLoading,
  setStopDebug,
  setErrorMessage,
  setChatLoading,
  storedQuery,
  stopDebug,
  generating,
  webSocket
}) {
  const { activeTab, setJobInputQuery, setCareerInputQuery } =
    useContext(GlobalContext);

  const messageEndRef = useRef(null);
  useEffect(() => {
    messageEndRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "end"
    });
  }, [chatData, debugMessage]);

  const handleGenerateText = (e) => {
    setStopDebug(true);
    setErrorMessage("");
    setBtnLoading(true);
    setChatLoading(true);
    setDebugMessage("");
    if (chatData?.length > 0 && chatData[chatData.length - 1].role === "ai") {
      chatData.pop();
    }
    webSocket.current?.send(
      JSON.stringify({
        message: storedQuery,
        chat_id: 1
      })
    );
  };
  const [hoveredCard, setHoveredCard] = useState(null);
  return (
    <>
      <div id="conversation-view">
        <div className="conversation-box w-[100%]">
          {chatData?.map((data, chatIndex) => (
            <div key={chatIndex}>
              {data.role === "user" && (
                <div className="sender-div  flex  my-2 ">
                  <div className="sender-message ">{data.content}</div>
                </div>
              )}
              {data.role === "ai" ? (
                <div className="receiver-div flex ">
                  <div className="receiver-message   px-4 py-1 rounded w-full">
                    {data.content.split("\n").map((paragraph, index) => (
                      <div
                        key={index}
                        className="mb-3 text-base xs:text-[14px] break-words"
                      >
                        <ReactMarkdown
                          components={{
                            a: ({ node, ...props }) => (
                              <a
                                {...props}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {props.children}
                              </a>
                            )
                          }}
                        >
                          {paragraph}
                        </ReactMarkdown>
                      </div>
                    ))}
                  </div>
                </div>
              ) : null}
            </div>
          ))}
          {debugMessage && (
            <div className="receiver-div flex">
              <div className="receiver-message px-4 py-1  rounded w-full">
                {debugMessage.split("\n").map((paragraph, index) => (
                  <p
                    key={index}
                    className="mb-3 text-base  break-words target:blank "
                  >
                    <ReactMarkdown>{paragraph}</ReactMarkdown>
                  </p>
                ))}
              </div>
            </div>
          )}
          {chatLoading && (
            <div className="receiver-div flex items-center">
              <div class="custom-loader"></div>
            </div>
          )}
          <div ref={messageEndRef} />

          {/* {!stopDebug && (
            <div className="flex  w-full mt-1 ">
              <GrRotateRight
                onClick={
                  !generating ? handleGenerateText : (e) => e.preventDefault()
                }
                className="text-gray "
              />
            </div>
          )} */}
          {chatData.length === 0 && activeTab === "0" && (
            <>
              <div className="  flex flex-col gap-2 justify-center items-center p-4 ">
                <h1 className="text-[#263238] font-bold lg:text-[27px] text-[18px]">
                  Welcome to Coach Chat
                </h1>
                <span className="text-[#494949] lg:text-[16px] text-[12px] font-normal">
                  Ask anything. Remember, more questions mean better results!
                </span>
              </div>
              <div className=" flex flex-col gap-4 mt-5">
                {CoachData.map((job) => (
                  <div
                    onClick={() => setCareerInputQuery(job.CoachDetail.prompt)}
                    key={job.id}
                    onMouseEnter={() => setHoveredCard(job.id)}
                    onMouseLeave={() => setHoveredCard(null)}
                    className="relative flex flex-col justify-center   cursor-pointer"
                  >
                    <p className="text-[#595959] lg:text-[17px] text-[12px] font-roboto bg-[#f3f8f8] p-2 rounded-[5px] w-[70%]">
                      {job.CoachDetail.name}
                    </p>
                  </div>
                ))}
              </div>
            </>
          )}
          {chatData.length === 0 && activeTab === "1" && (
            <div className="   h-full px-8">
              <div className="  flex flex-col gap-2 items-center mt-[10%] ">
                <h1 className="text-[#263238] lg:text-[30px] text-[20px] font-[700]">
                  Explore popular <span className="text-[#FF5E59]">jobs</span>
                </h1>
                <span className="text-[#494949] lg:text-[16px] text-[12px] font-normal text-center">
                  Discover CrewDog's Job Board! Powered by CrewDog AI, it lets
                  you search for jobs naturally, without forms or filters. Just
                  type like a conversation, and our AI delivers personalized
                  listings. Experience a smarter, hassle-free job search. You
                  can ask questions in your native language—even Gaelic.
                </span>
                <p className="text-[#FF5E59]  text-[18px] font-[500] mt-2">
                  Try it today!
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default ChatView;
