import React from "react";
import { Link } from "react-router-dom";
import { FaFacebookF } from "react-icons/fa";
import { TiSocialInstagram } from "react-icons/ti";
import { FaTwitter } from "react-icons/fa";

function Footer() {
  return (
    <footer className="bg-white w-full py-7 px-2 text-base flex flex-col justify-center items-center gap-8">
      <div className="flex flex-wrap gap-4 items-center justify-center text-center">
        <Link to="#" className="hover:text-primary">
          Accessibility
        </Link>
        <Link to="#" className="hover:text-primary">
          Advertise with us
        </Link>
        <Link to="#" className="hover:text-primary">
          Contact Us
        </Link>
        <Link to="#" className="hover:text-primary">
          Disclaimer
        </Link>
        <Link to="#" className="hover:text-primary">
          Privacy and Cookies
        </Link>
        <Link to="#" className="hover:text-primary">
          Site Map
        </Link>
      </div>
      <div className="flex gap-4 items-center justify-center">
        <a
          href="#"
          className="bg-primary text-white w-9 h-9 flex justify-center items-center rounded-full"
        >
          <FaFacebookF />
        </a>
        <a
          href="#"
          className="bg-primary text-white w-9 h-9 flex justify-center items-center rounded-full"
        >
          <TiSocialInstagram />
        </a>
        <a
          href="#"
          className="bg-primary text-white w-9 h-9 flex justify-center items-center rounded-full"
        >
          <FaTwitter />
        </a>
      </div>
      <p className="text-center">© Copyright 2024</p>
    </footer>
  );
}

export default Footer;
