const Auth = (state, action) => {
  switch (action.type) {
    case "SET_USER_INFO":
      return {
        ...state,
        userInfo: action.payload,
      };
    case "SET_SUGGESTION_QUERY":
      return {
        ...state,
        suggestionQuery: action.payload,
      };

    // job
    case "SET_JOB_CHAT_DATA":
      return {
        ...state,
        jobChatData: [...state.jobChatData, ...action.payload],
      };

    case "SET_JOB_CHAT_LOADING":
      return {
        ...state,
        jobChatLoading: action.payload,
      };
    case "SET_JOB_ERROR_MESSAGE":
      return {
        ...state,
        jobErrorMessage: action.payload,
      };
    case "SET_JOB_INPUT_QUERY":
      return {
        ...state,
        jobInputQuery: action.payload,
      };
    case "SET_JOB_SEND_BTN_LOADING":
      return {
        ...state,
        jobSendBtnLoading: action.payload,
      };

    case "SET_JOB_STOPDEBUG":
      return {
        ...state,
        jobStopDebug: action.payload,
      };
    case "SET_JOB_STOREDQUERY":
      return {
        ...state,
        jobStoredQuery: action.payload,
      };
    case "SET_JOB_GENERATING":
      return {
        ...state,
        jobGenerating: action.payload,
      };

    case "CLEAR_CHAT_DATA":
      return {
        ...state,
        jobChatData: [],
      };
    // profile
    case "SET_PROFILE_CHAT_DATA":
      return {
        ...state,
        profileChatData: [...state.profileChatData, ...action.payload],
      };

    case "SET_PROFILE_CHAT_LOADING":
      return {
        ...state,
        profileChatLoading: action.payload,
      };
    case "SET_PROFILE_ERROR_MESSAGE":
      return {
        ...state,
        profileErrorMessage: action.payload,
      };
    case "SET_PROFILE_INPUT_QUERY":
      return {
        ...state,
        profileInputQuery: action.payload,
      };
    case "SET_PROFILE_SEND_BTN_LOADING":
      return {
        ...state,
        profileSendBtnLoading: action.payload,
      };

    case "SET_PROFILE_STOPDEBUG":
      return {
        ...state,
        profileStopDebug: action.payload,
      };
    case "SET_PROFILE_STOREDQUERY":
      return {
        ...state,
        profileStoredQuery: action.payload,
      };
    case "SET_PROFILE_GENERATING":
      return {
        ...state,
        profileGenerating: action.payload,
      };
    case "CLEAR_PROFILE_CHAT_DATA":
      return {
        ...state,
        profileChatData: [],
      };
    //new profile
    case "SET_NEW_PROFILE_CHAT_DATA":
      return {
        ...state,
        newProfileChatData: [...state.newProfileChatData, ...action.payload],
      };

    case "SET_NEW_PROFILE_CHAT_LOADING":
      return {
        ...state,
        newProfileChatLoading: action.payload,
      };
    case "SET_NEW_PROFILE_ERROR_MESSAGE":
      return {
        ...state,
        newProfileErrorMessage: action.payload,
      };
    case "SET_NEW_PROFILE_INPUT_QUERY":
      return {
        ...state,
        newProfileInputQuery: action.payload,
      };
    case "SET_NEW_PROFILE_SEND_BTN_LOADING":
      return {
        ...state,
        newProfileSendBtnLoading: action.payload,
      };

    case "SET_NEW_PROFILE_STOPDEBUG":
      return {
        ...state,
        newProfileStopDebug: action.payload,
      };
    case "SET_NEW_PROFILE_STOREDQUERY":
      return {
        ...state,
        newProfileStoredQuery: action.payload,
      };
    case "SET_NEW_PROFILE_GENERATING":
      return {
        ...state,
        newProfileGenerating: action.payload,
      };
    case "CLEAR_NEW_PROFILE_CHAT_DATA":
      return {
        ...state,
        newProfileChatData: [],
      };
    // doc
    case "SET_DOC_CHAT_DATA":
      return {
        ...state,
        docChatData: [...state.docChatData, ...action.payload],
      };

    case "SET_DOC_CHAT_LOADING":
      return {
        ...state,
        docChatLoading: action.payload,
      };
    case "SET_DOC_ERROR_MESSAGE":
      return {
        ...state,
        docErrorMessage: action.payload,
      };
    case "SET_DOC_INPUT_QUERY":
      return {
        ...state,
        docInputQuery: action.payload,
      };
    case "SET_DOC_SEND_BTN_LOADING":
      return {
        ...state,
        docSendBtnLoading: action.payload,
      };
    case "SET_DOC_STOPDEBUG":
      return {
        ...state,
        docStopDebug: action.payload,
      };
    case "SET_DOC_STOREDQUERY":
      return {
        ...state,
        docStoredQuery: action.payload,
      };
    case "SET_DOC_GENERATING":
      return {
        ...state,
        docGenerating: action.payload,
      };
    case "CLEAR_DOC_CHAT_DATA":
      return {
        ...state,
        docChatData: [],
      };

    //career
    case "SET_CAREER_CHAT_DATA":
      return {
        ...state,
        careerChatData: [...state.careerChatData, ...action.payload],
      };

    case "SET_CAREER_CHAT_LOADING":
      return {
        ...state,
        careerChatLoading: action.payload,
      };
    case "SET_CAREER_ERROR_MESSAGE":
      return {
        ...state,
        careerErrorMessage: action.payload,
      };
    case "SET_CAREER_INPUT_QUERY":
      return {
        ...state,
        careerInputQuery: action.payload,
      };
    case "SET_CAREER_SEND_BTN_LOADING":
      return {
        ...state,
        careerSendBtnLoading: action.payload,
      };
    case "SET_CAREER_STOPDEBUG":
      return {
        ...state,
        careerStopDebug: action.payload,
      };
    case "SET_CAREER_STOREDQUERY":
      return {
        ...state,
        careerStoredQuery: action.payload,
      };
    case "SET_CAREER_GENERATING":
      return {
        ...state,
        careerGenerating: action.payload,
      };
    case "CLEAR_CAREER_CHAT_DATA":
      return {
        ...state,
        careerChatData: [],
      };

    // active tab
    case "SET_ACTIVE_TABS":
      return {
        ...state,
        activeTab: action.payload,
      };
    // upload cv
    case "SET_UPLOAD_CV_DATA":
      return {
        ...state,
        cvDetail: action.payload,
      };
    default:
      return state;
  }
};
export default Auth;
