/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { RiSendPlaneFill } from "react-icons/ri";
import "../../styles/Globals.css";
import ReconnectingWebSocket from "reconnecting-websocket";
// import Voice from "../../assets/images/Voice.png";
import { CiCirclePlus } from "react-icons/ci";
import { FaRegStopCircle } from "react-icons/fa";
import GlobalContext from "../../context/GlobalContext";
import { RiSendPlane2Fill } from "react-icons/ri";

function Input({
  inputQuery,
  setInputQuery,
  setChatData,
  setChatLoading,
  setErrorMessage,
  setBtnLoading,
  clearChatData,
  btnLoading,
  stopDebug,
  setStopDebug,
  setStoredQuery,
  setGenerating,
  generating,
  setDebugMessage,
  webSocketUrl,
  webSocket
}) {
  const { activeTab } = useContext(GlobalContext);

  const ConnectWebSockets = () => {
    webSocket.current.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.type === "debug") {
        setChatLoading(false);
        setGenerating(true);
        if (stopDebug) {
          setDebugMessage((prevMessage) => prevMessage + data.message);
        }

        setErrorMessage("");
      } else {
        setGenerating(false);
        setBtnLoading(false);
        setChatLoading(false);
        const newMessage = { role: "ai", content: data["message"] };
        if (stopDebug) {
          setChatData([newMessage]);
          setDebugMessage("");
        }
        setErrorMessage("");
      }

      if (data.type === "error") {
        setErrorMessage("an error occurred! please try again later");
        setBtnLoading(false);
        setChatLoading(false);
      }
    };

    webSocket.current.onclose = (e) => {
      setErrorMessage("an error occurred! please try again later");
      setBtnLoading(false);
      setChatLoading(false);
    };

    webSocket.current.onerror = (err) => {
      setErrorMessage("an error occurred! please try again later");
      setBtnLoading(false);
      setChatLoading(false);
    };
  };
  useEffect(() => {
    webSocket.current = new ReconnectingWebSocket(webSocketUrl);
    ConnectWebSockets(webSocket);
  }, []);

  useEffect(() => {
    ConnectWebSockets();
  }, [stopDebug]);
  const handleSubmit = (e) => {
    e.preventDefault();
    const messageWithLineBreaks = inputQuery.replace(/\n/g, "\n");
    webSocket.current?.send(
      JSON.stringify({
        message: inputQuery,
        chat_id: 1
      })
    );
    setStoredQuery(inputQuery);
    setDebugMessage("");
    setStopDebug(true);
    setBtnLoading(true);
    setChatLoading(true);
    setChatData([{ role: "user", content: messageWithLineBreaks }]);
    setInputQuery("");
  };

  const handleStopClick = () => {
    setStopDebug(false);
    setBtnLoading(false);
    setChatLoading(false);
  };
  const handleChange = (e) => {
    setInputQuery(e.target.value);
  };

  useEffect(() => {
    const textarea = document.querySelector(".input");
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = textarea.scrollHeight + "px";
    }
  }, [inputQuery]);

  useEffect(() => {
    const textarea = document.querySelector(".input");
    if (textarea) {
      textarea.removeAttribute("style");
      textarea.style.height = "20px";
      textarea.style.minHeight = "20px";
      textarea.style.maxHeight = "100px";
      textarea.style.resize = "none";
      const scrollHeight = textarea.scrollHeight;
      if (scrollHeight <= 150) {
        textarea.style.height = `${scrollHeight}px`;
      } else {
        textarea.style.height = "100px";
      }
      textarea.addEventListener("input", () => {
        const scrollHeight = textarea.scrollHeight;
        if (scrollHeight <= 150) {
          textarea.style.height = `${scrollHeight}px`;
        } else {
          textarea.style.height = "100px";
        }
      });
    }
  }, [inputQuery]);

  const [recognition, setRecognition] = useState(null);

  useEffect(() => {
    if ("SpeechRecognition" in window || "webkitSpeechRecognition" in window) {
      const SpeechRecognitionAPI =
        window.SpeechRecognition || window.webkitSpeechRecognition;
      const recognitionInstance = new SpeechRecognitionAPI();
      setRecognition(recognitionInstance);
    } else {
      console.error("Browser doesn't support speech recognition.");
    }
  }, []);

  useEffect(() => {
    if (recognition) {
      recognition.onresult = (event) => {
        const transcript = event.results[0][0].transcript;
        setInputQuery(transcript);
      };
    }
  }, [recognition, setInputQuery]);
  const handlecleardata = () => {
    clearChatData();
    setDebugMessage("");
    setStopDebug(true);
    setChatLoading(false);
  };
  return (
    <>
      <div className="flex items-center xs:justify-center gap-3  w-[100%] border-t">
        <div className="lg:w-full xs:w-full flex items-center justify-between  shadow-custom my-2 py-1 pl-2 pr-3 ">
          <div className="flex items-center w-[100%]">
            {activeTab === "1" ? (
              <div
                className="flex items-center justify-between gap-1 bg-[#F5F5F5] border border-[#CBCBCB] py-2 px-2  lg:w-[130px] 2xl:w-[120px] rounded-[30px] cursor-pointer"
                onClick={handlecleardata}
              >
                <CiCirclePlus
                  style={{
                    fontSize: "15px",
                    color: "black",
                    fontWeight: "bold"
                  }}
                />
                <p className="xs:hidden lg:flex text-[12px] text-black">
                  New Topic
                </p>
              </div>
            ) : (
              ""
            )}

            <input
              className="w-full  xs:text-[14px] lg:text-base px-[10px] border-none touch-manipulation  focus:outline-none placeholder-text bg-transparent "
              type="text"
              placeholder={
                activeTab === "0"
                  ? "Enter your question ..."
                  : activeTab === "1"
                  ? "Search for jobs ..."
                  : ""
              }
              onChange={(e) => handleChange(e)}
              onKeyPress={(e) => {
                if (e.key === "Enter" && !e.shiftKey) {
                  e.stopPropagation();
                  if (inputQuery && !btnLoading && !generating) {
                    handleSubmit(e);
                  }
                }
              }}
              value={inputQuery}
              style={{
                fontSize: "16px",
                maxHeight: "40px",
                minHeight: "20px",
                resize: "none"
              }}
              required
              name="footer-input"
            />
          </div>
          <div className="flex items-center cursor-pointer">
            {btnLoading ? (
              <FaRegStopCircle
                onClick={handleStopClick}
                className="text-red-500  rounded-full cursor-pointer text-[30px]"
              />
            ) : (
              <>
                {inputQuery && !btnLoading && !generating ? (
                  <div>
                    <RiSendPlane2Fill
                      className=" rounded-full cursor-pointer text-[35px] text-primary"
                      onClick={
                        inputQuery && !btnLoading && !generating
                          ? handleSubmit
                          : (e) => e.preventDefault()
                      }
                    />
                  </div>
                ) : (
                  <div>
                    <RiSendPlane2Fill className=" rounded-full text-[35px] text-[#1E2444]" />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
export default Input;
